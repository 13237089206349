<template>
  <div class="shipping">
    <span class="sitetitle">
      <div>{{$t('m.buy.shipping_address')}}</div>
      <span @click="dialogVisible = true">{{$t('m.buy.add_shipping_address')}}+</span>
    </span>
    <ul class="showsite">
      <li class="sitelist"
      v-for="(item,index) in datalist" :key="index"
      :class="{active:isactive==index}"
      v-show="index<=2?true:showallgg"
      @click="isact(item,index)">
        <span>{{item.name}}</span>
        <span>{{item.phoneNumber}}</span>
        <span>{{item.detailAddress}}</span>
        <i @click="dialogAmend = true,changeaddress(item)">{{$t('m.buy.modification')}}</i>
        <i @click="deleteaddress(item.id, index)">{{$t('m.buy.delete')}}</i>
      </li>
      <span class="showall" v-if="datalist.length>=3"
      @click="this.showallgg=!showallgg">{{$t('m.buy.View_all')}}
        <el-icon>
          <component :is="showallgg?'ArrowUpBold':'ArrowDownBold'"></component>
        </el-icon>
      </span>
    </ul>

    <!-- 新增收货地址弹窗 -->
    <el-dialog v-model="dialogVisible"
    title = "新增收货地址"
     width="40%">
      <div class="addsite">
        <div class="add-item">
          <div class="add-item-left">{{$t('m.buy.Name_of_the_consignee')}}</div>
          <div class="add-item-right">
            <el-input v-model="name" :placeholder="$t('m.buy.Please_enter_Name_of_the_consignee')"></el-input>
          </div>
        </div>
        <div class="add-item">
          <div class="add-item-left">{{$t('m.buy.Mobile_phone_number')}}</div>
          <div class="add-item-right">
            <el-input
              v-model="phone"
              :placeholder="$t('m.buy.Please_enter_Mobile_phone_number')"
            ></el-input>
          </div>
        </div>
        <!-- <div class="add-item"> // 三级联动数据
          <div class="add-item-left">所在地区</div>
          <div class="add-item-right">
            <el-cascader
              size="large"
              :options="options"
              v-model="selectedOptions"
              @change="addressChange"
            >
            </el-cascader>
          </div>
        </div> -->
        <div class="add-item">
          <div class="add-item-left">{{$t('m.buy.Full_address')}}</div>
          <div class="add-item-right">
            <el-input
              v-model="detailaddress"
              :placeholder="$t('m.buy.Please_enter_Full_address')"
            ></el-input>
          </div>
        </div>
        <div class="footbtn">
          <el-button @click="sub" class="save">{{$t('m.buy.Save_and_Use')}}</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 修改收货地址弹窗 -->
    <el-dialog v-model="dialogAmend"
    title = "修改收货地址"
     width="40%">
      <div class="addsite">
        <div class="add-item">
          <div class="add-item-left">{{$t('m.buy.Name_of_the_consignee')}}</div>
          <div class="add-item-right">
            <el-input v-model="changeaddressmes.name" :placeholder="$t('m.buy.Please_enter_Name_of_the_consignee')"></el-input>
          </div>
        </div>
        <div class="add-item">
          <div class="add-item-left">{{$t('m.buy.Mobile_phone_number')}}</div>
          <div class="add-item-right">
            <el-input
              v-model="changeaddressmes.phoneNumber"
              :placeholder="$t('m.buy.Please_enter_Mobile_phone_number')"
            ></el-input>
          </div>
        </div>
        <!-- <div class="add-item"> // 三级联动数据
          <div class="add-item-left">所在地区</div>
          <div class="add-item-right">
            <el-cascader
              size="large"
              :options="options"
              v-model="selectedOptions"
              @change="addressChange"
            >
            </el-cascader>
          </div>
        </div> -->
        <div class="add-item">
          <div class="add-item-left">{{$t('m.buy.Full_address')}}</div>
          <div class="add-item-right">
            <el-input
              v-model="changeaddressmes.detailAddress"
              :placeholder="$t('m.buy.Please_enter_Full_address')"
            ></el-input>
          </div>
        </div>
        <div class="footbtn">
          <el-button @click="modifyaddress" class="save">{{$t('m.buy.Save_and_Use')}}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ref } from 'vue-demi'
import emitter from '../../tools/bus'
import http from '../../api/http'
import { getStore } from '../../tools/storage'
import { ElMessage } from 'element-plus'
export default {
  name: 'WuLiu',
  data () {
    return {
      value: true,
      showallgg: ref(false),
      datalist: '', // 地址list
      dialogVisible: false, // 新增收货地址弹窗开关
      dialogAmend: false, // 修改收货地址弹窗开关
      name: '', // 新增收货人name
      phone: '', // 手机号
      selectedOptions: [], // 地区信息
      detailaddress: '', // 详细地址
      addressmessage: '',
      setDefaults: 1,
      changeaddressmes: '' // 修改存储数据
    }
  },
  mounted () {
    this.getclosedlists()
    // this.datalist = this.$props.wuliumes
    // console.log(this.datalist)
  },
  methods: {
    isact (i, index) { // 切换选择的地址
      this.isactive = index
      // console.log(i)
      emitter.emit('wuliumesArea', i.id) // 可传出告诉选择的第几个地址
    },
    sub () { // 新增保存
      if (!this.name) {
        ElMessage.warning({
          message: '请填写姓名',
          type: 'error'
        })
        return
      }
      const reg = 11 && /^((13|14|15|17|18|16|19|11|12)[0-9]{1}\d{8})$/
      if (this.phone.length < 1) {
        ElMessage.warning({
          message: '手机号不能为空',
          type: 'error'
        })
        return
      } else if (!reg.test(this.phone)) {
        ElMessage.warning({
          message: '手机格式不正确',
          type: 'error'
        })
        return
      }
      if (!this.detailaddress) {
        ElMessage.warning({
          message: '请填写详细地址',
          type: 'error'
        })
        return
      }

      this.value ? (this.setDefaults = 1) : (this.setDefaults = 0)
      const usermessage = getStore('usermessage')
      const uid = JSON.parse(usermessage).id
      this.dialogVisible = false
      const loading = this.$loading({
        lock: true,
        text: '提交中'
      })
      const data = {
        // city: this.city,
        detailAddress: this.detailaddress,
        memberId: uid,
        name: this.name,
        phoneNumber: this.phone,
        // province: this.province,
        // region: this.area,
        defaultStatus: this.setDefaults
        // selectedOptions: JSON.stringify(this.selectedOptions)
      }
      // console.log(data)
      try {
        http.post('mall-portal/member/address/add', data, true)
          .then(res => {
            console.log(res)
            loading.close()
            this.addressmessage = res.data.data
            this.$store.state.address = res.data.data
            ElMessage.success({
              message: '添加成功'
            })
            this.name = ''
            this.phone = ''
            this.detailaddress = ''
            this.selectedOptions = []
            this.getclosedlists()
            // console.log(this.$store.state.address)
          }).catch(err => {
            console.log(err)
          })
      } catch (e) {
        loading.close()
        // TODO handle the exception
      }
    },
    getclosedlists () { // 获取所有地址信息
      http.get('mall-portal/member/address/list')
        .then(res => {
          // console.log(res)
          this.datalist = res.data.data
          if (this.datalist.length > 0) {
            this.isact(this.datalist[0], 0)
          } else {
            emitter.emit('wuliumesArea', '')
            ElMessage.error({
              message: '请添加收货地址'
            })
          }
        }).catch(err => {
          console.log(err)
        })
    },
    changeaddress (item) { // 修改信息填入弹框
      console.log(item)
      // console.log(typeof item.selectedOptions)
      item.defaultStatus === 1
        ? (item.defaultStatus = true)
        : (item.defaultStatus = false)
      if (typeof item.selectedOptions === 'string') {
        item.selectedOptions = JSON.parse(item.selectedOptions)
      }
      this.changeaddressmes = item
    },
    async modifyaddress () { // 修改内容保存确定
      if (!this.changeaddressmes.name) {
        ElMessage.warning({
          message: '请填写收货人姓名',
          type: 'error'
        })
        return
      }
      if (!this.changeaddressmes.phoneNumber) {
        ElMessage.warning({
          message: '请填写姓名',
          type: 'error'
        })
        return
      }
      const reg = 11 && /^((13|14|15|17|18|16|19|11|12)[0-9]{1}\d{8})$/
      if (this.changeaddressmes.phoneNumber.length < 1) {
        ElMessage.warning({
          message: '手机号不能为空',
          type: 'error'
        })
        return
      } else if (!reg.test(this.changeaddressmes.phoneNumber)) {
        ElMessage.warning({
          message: '手机格式不正确',
          type: 'error'
        })
        return
      }
      if (!this.changeaddressmes.detailAddress) {
        ElMessage.warning({
          message: '请填写详细地址',
          type: 'error'
        })
      }

      const usermessage = getStore('usermessage')
      const uid = JSON.parse(usermessage).id
      let type = 0
      this.changeaddressmes.defaultStatus ? (type = 1) : (type = 0)

      const data = {
        detailAddress: this.changeaddressmes.detailAddress,
        memberId: uid,
        name: this.changeaddressmes.name,
        phoneNumber: this.changeaddressmes.phoneNumber,

        defaultStatus: type
      }
      console.log(data)
      try {
        http.post(`/mall-portal/member/address/update/${this.changeaddressmes.id}`, data, true)
          .then(res => {
            console.log(res)
            this.addressmessage = res.data.data
            ElMessage.success({
              message: '修改成功!'
            })
            this.dialogAmend = false
            this.$store.state.address = res.data.data
            // console.log(this.$store.state.address)
          }).catch(err => {
            console.log(err)
          })
      } catch (e) {
        // TODO handle the exception
      }
    },
    // 删除地址
    deleteaddress (id, index) {
      this.$confirm('您确定删除此地址吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.confirmdelete(id, index)
        })
        .catch(() => {})
    },
    async confirmdelete (id, index) {
      console.log(id)
      console.log(index)
      try {
        http.post(`mall-portal/member/address/delete/${id}`, {})
          .then(res => {
            console.log(res)
            if (res.data.flag) {
              this.datalist.splice(index, 1)
              // if (id === this.$store.state.address.id) {
              //   console.log('id一样')
              //   this.addressmessage = this.datalist[0] || ''
              //   this.$store.state.address = this.datalist[0] || ''
              // }
              ElMessage.success({
                type: 'success',
                message: '删除成功!'
              })
              return ''
            }
            // this.datalist.splice(index, 1)
            // console.log(this.$store.state.address)
          }).catch(err => {
            console.log(err)
          })
      } catch (e) {
        // TODO handle the exception
      }
    }
  },
  props: ['wuliumes'],
  setup (props, ctx) {
    // console.log(props.wuliumes)
    return {
      isactive: ref(0)
    }
  }
}
</script>

<style lang="scss" >
.shipping{
  display: flex;
  flex-flow: column;
  .sitetitle{
    display: flex;
    justify-content: space-between;
    margin: 20px;
    margin-right: 0;
    div{
      color: #999999;
      font-size: 14px;
    }
    span{
      color: #C6824E;
      cursor: pointer;
    }
  }
  .showsite{
    padding-left: 110px;
    .sitelist{
      display: flex;
      font-size: 14px;
      color: #999999;
      padding: 10px 20px;
      border: 1px solid transparent;
      transition: all 0.2s;
      align-items: center;
      span{
        width: 16%;
        min-height: 18px;
        line-height: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        // margin-right: 40px;
      }
      span:nth-child(2){
        width: 20%;
      }
      span:nth-child(3){
        flex: 1;
      }
      i{
        margin-right: 20px;
        cursor: pointer;
      }
    }
    .showall{
      display: block;
      margin: 0 auto;
      width: 100px;
      cursor: pointer;
      margin-top: 10px;
    }

    .active{
      color: #333333;
      background: #FFEEE0;
      border: 1px solid #C6824E;
      i{
        color: #C6824E;
      }
    }
  }
  .el-overlay{
    .el-overlay-dialog{
      .el-dialog{
        .el-dialog__header{
          .el-dialog__title{
            width: 100%;
            display: inline-block;
            text-align: center;
          }
        }
        .el-dialog__body{
          .addsite{
            .add-item{
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 10px 0;
              .add-item-left {
                width: 90px;
                text-align: left;
                color: #595757;
                font-size: 14px;
              }
              .add-item-right {
                flex: 1;
              }
            }
            .footbtn{
              width: 100%;
              display: flex;
              .save{
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
}
</style>
